import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'

import DoneModal from '../../modals/DoneModal'

import QuestionsSection from '../QuestionsSection'
import { postTest } from '../../../helpers/fetchHelper'
import { isAllObjectValuesEmpty, isObject } from '../../../helpers/helperFunctions'
import { rewardPlug } from '../../../helpers/plugs'

import LeftArrow from '../../../../assets/images/arrow_navy_blue_left.svg'
import InactiveRightArrow from '../../../../assets/images/arrow_grey_right.svg'
import DownloadIcon from '../../../../assets/images/download_icon.svg'

function Test({
   authToken,
   test,
   profile,
   stepsAmount,
   step,
   changeStep,
   updateProgress }) {

   const [formData, setFormData] = useState({
      forBack: {},
      forFront: {}
   })
   const [openEl, setOpenEl] = useState(test.sections[0].id)
   const [showAlert, setShowAlert] = useState(false)
   const [showDoneModal, setShowDoneModal] = useState(false)
   const [newProgress, setNewProgress] = useState(null)

   useEffect(() => {
      getDefaultAnswers()
   }, [])

   const getAllTestQuestions = () => test.sections.map(section => section.questions).flat()

   const filterQuestionsByAnswer = () => getAllTestQuestions().filter(question => question.answer)

   const normalizeDefaultAnswer = (question) => {
      const answer = question.answer.answer
      if (answer) {
         return [question.id, Object.values(answer)[0]]
      } else {
         return null
      }
   }

   function getDefaultAnswers() {
      const questionsWithDefaultAnswer = filterQuestionsByAnswer()
      const defaultAnswersList = questionsWithDefaultAnswer.map(question => normalizeDefaultAnswer(question)).filter(answer => answer)
      const defaultAnswers = Object.fromEntries(defaultAnswersList)

      setFormData(prevState => ({
         forBack: prevState.forBack,
         forFront: {
            ...prevState.forFront,
            ...defaultAnswers
         }
      }))
   }

   const handleSectionClick = (e) => {
      setOpenEl(e.target.id)
   }

   const isValueEmpty = (value) => {
      if (!value) return true

      if (isObject(value) && isAllObjectValuesEmpty(value)) return true
      return false
   }

   const handleChange = (event, question_type) => {
      const { name, value } = event.target

      setFormData(prevState => {

         const newForFront = {
            ...prevState.forFront,
            [name]: value
         }

         if (isValueEmpty(value) && prevState.forBack[name]) {
            const { [name]: _, ...newForBack } = prevState.forBack
            return {
               forBack: newForBack,
               forFront: newForFront
            }
         }

         if (value) {
            return {
               forBack: {
                  ...prevState.forBack,
                  [name]: {
                     [question_type]: value
                  }
               },
               forFront: newForFront
            }
         }

         return {
            forBack: prevState.forBack,
            forFront: newForFront
         }
      })
   }

   const onValidationFalse = () => {
      setShowAlert(true)
      setTimeout(() => {
         setShowAlert(false)
      }, 3000)
   }

   const handleSubmit = () => {

      const formDataArr = Object.entries(formData.forBack)

      if (!formDataArr.length) {
         onValidationFalse()
         return
      }

      const objToSubmit = formDataArr.map(([key, value]) => ({
         question_id: key,
         answer: value,
         answer_header_id: profile.answer_header_id
      }))

      postTest(authToken, objToSubmit, profile.lesson_id, profile.id, test.id)
         .then(({ progress }) => {
            setShowDoneModal(true)
            setNewProgress(progress)
         })
         .catch(err => console.log(err))
   }

   const onCloseDoneModal = () => {
      if (newProgress) updateProgress(newProgress)

      document.querySelector('body').scrollTo(0, 0)

      if (stepsAmount === step) {
         changeStep(0, profile.id, test.id)
      } else {
         changeStep(step + 1, profile.id, test.id)
      }
   }

   const getSortedSections = () => {
      const sections = [...test.sections]
      if (sections.length <= 1) return sections
      return sections.sort((section, nextSection) => section.sort_order - nextSection.sort_order)
   }

   const onNavigateButtonsClick = (newStep) => {
      document.querySelector('body').scrollTo(0, 0)
      changeStep(newStep)
   }

   const sortedSections = getSortedSections()
   return (
      <>
         <div className="test">
            <h1 className="text_to_center">{ReactHtmlParser(profile.title || " ")}</h1>
            <h4 className="mt-40">{ReactHtmlParser(test.title || " ")}</h4>
            <p className="mt-20 mb-20">{ReactHtmlParser(test.subtitle || " ")}</p>
            <div className='text-center p-4'>
               <a download 
                  className='btn_primary'
                  
                  href={`/lesson/${profile.lesson_id}/download_worksheet.pdf` }

               >Download Worksheet &nbsp;
               <img className="btn_secondary-img" src={DownloadIcon} />
               </a>
               <br/> <br/>
               <span>Please submit answers before downloading to print answers.</span>
            </div>
            { console.log(
                  formData.forFront)}
            {
               sortedSections.map(section =>
                  <QuestionsSection
                     key={section.id}
                     section={section}
                     handleChange={handleChange}
                     handleSectionClick={handleSectionClick}
                     openEl={openEl}
                     formData={formData.forFront}
                     needToTransferAnswerType={true}
                     propsAdapterInstructions={{
                        answer_key: "id",
                        question_options: "question_options",
                        question: "question_label",
                        answer_type: "widget_type",
                     }}
                  />)
            }
            <div className="test__btn-container flex_container mt-50">
               <div className="d-flex">
                  <button className={step === 0 ? "d-none" : "btn_secondary mr-20"} onClick={() => onNavigateButtonsClick(step - 1)}>
                     <img className="btn_secondary-img" src={LeftArrow} /> Back
                  </button>
                  <a target="_blank" href={profile.pdf_file_url} className={profile.pdf_file_url ? "btn_secondary--orange" : "d-none"}>
                     <img className="btn_secondary-img" src={DownloadIcon} /> Download PDF
                  </a>
               </div>
               <div className="d-flex">
                  <button className="btn_primary" onClick={handleSubmit}>
                     Save
                  </button>
                  <button className={step === stepsAmount ? "d-none" : "btn_primary ml-20"} onClick={() => onNavigateButtonsClick(step + 1)}>
                     Next <img className="btn_secondary-img next" src={InactiveRightArrow} />
                  </button>
               </div>
            </div>

            <div className="test__btn-container-mob mt-50">
               <div className={step === 0 || step === stepsAmount ? "one-btn__container" : "flex_container"}>
                  <button className={step === 0 ? "d-none" : "btn_secondary"} onClick={() => onNavigateButtonsClick(step - 1)}>
                     <img className="btn_secondary-img" src={LeftArrow} /> Back
                  </button>
                  <button className={step === stepsAmount ? "d-none" : "btn_primary"} onClick={() => onNavigateButtonsClick(step + 1)}>
                     Next <img className="btn_secondary-img next" src={InactiveRightArrow} />
                  </button>
               </div>
               <button className="btn_primary w-100 mt-20" onClick={handleSubmit}>
                  Save
               </button>
               <a target="_blank" href={profile.pdf_file_url} className={profile.pdf_file_url ? "btn_secondary--orange w-100 mt-20" : "d-none"}>
                  <img className="btn_secondary-img" src={DownloadIcon} /> Download PDF
               </a>
            </div>
            {
               showAlert &&
               <div className="actions mt-2">
                  <p>Please finish a few questions</p>
               </div>
            }
         </div >
         {
            showDoneModal &&
            <>
               <DoneModal
                  reward={rewardPlug}
                  setSeenReward={onCloseDoneModal}
               />
            </>
         }
      </>
   )
}

export default Test