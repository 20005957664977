import React, { useEffect, createContext, useState } from 'react'
import { Modal} from 'semantic-ui-react'
import GroupForm from '../../../shared/components/GroupForm'
import BookedMeetings from '../sections/zoom/BookedMeetings'
import GroupsCalendar from '../sections/GroupsCalendar'

const formContext = createContext()

function GroupMeetingModal({
  current_user, b2b_user, meetings_data, 
  authToken , account , role_access, 
  calendar_img, showSession, setShowSession,
  showGroupModal, setShowGroupModal 
})
{
  const [showGroups, setShowGroups] = useState(true)
  const [meetingId, setMeetingId] = useState(null)
  const [activeCredits, setActiveCredits] = useState(current_user.active_credits)
  const [credits, setCredits] = useState(activeCredits > 0 ? activeCredits : 1)
  const [groupsData, setGroupsData] = useState(meetings_data.groups_data)
  const [showCalendar, setShowCalendar] = useState(false)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const booking_link = role_access.find(role => role.id === 11)?.content
  const show_buy_credit = role_access.find(role => role.id === 30)
  const firstName = current_user?.first_name ? "firstName="+current_user.first_name+"&" : ""
  const lastName = current_user?.last_name ? "lastName="+current_user.last_name+"&" : ""
  const phone = current_user?.phone ? "phone="+current_user.phone+"&" : ""
  const email = current_user?.email ? "email="+current_user.email : ""

  const BookMeeting = (e) => {
    setMeetingId(e.target.id)
    setCredits(activeCredits)
    setShowGroups(!showGroups)
  }

  useEffect(() => {
      if (credits === 0){
        setCredits(1)
      }
      setShowGroups(true)
  }, [showGroupModal])
  

  const unSubscribe = (meeting_id) => {
    
    fetch("/group-unsubscribe", {
      credentials: 'same-origin',
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          'X-CSRF-Token': authToken,
      },
      body: JSON.stringify({
        meeting_id,
      })
    })
    .then(response => response.json())
      .then(() => {
          const newState = groupsData.map(group => {
            if (group.id === meeting_id) {
              return {...group, subscribed: false}
            }
            return group
          });
          setGroupsData(newState)

        })
    .catch(err => {
      console.log(err)
    })

  }

  return (
    <>
      <>
        <div className='mb-5 pt-4 pl-0' >
          {role_access.find(item => item.id == 12) &&
            <GroupsCalendar 
              calendar_img={ calendar_img }
              showCalendar={showCalendar}
              setShowCalendar={setShowCalendar}
              />
          }
          <div className='row mt-3 '>
            {role_access.find(item => item.id == 12) &&
              <div className='col-6 col-sm-12 col-md-6'>
                <div className="btn_secondary_orange book-group" onClick={()=>setShowGroupModal(true)}>Book a Group </div>
              </div>
            }
            {role_access.find(item => item.id == 11)?.content &&
              <div className='col-6 col-sm-12 col-md-6'>
                  <div className={"btn_secondary_orange"} onClick={()=>setShowSession(true)}>Book a Session</div>
              </div>
            } 

            
          </div>

          {role_access.find(item => item.id == 11 || item.id == 12) &&
            <>
              <div className='text-left mt-1'><span>Upcoming Meetings</span></div>
              <BookedMeetings groupsData= {groupsData} appointments = { meetings_data.appointments } />
            </>
          }

        </div>  
      
        <Modal
          centered={true}
          open={showGroupModal}
          tabIndex="-1"
          role="dialog"
          size="large"
          dimmer={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
          className='group-list'
        >

          {credits < 1 ? 
          <div className='p-3 col-12'>
            <button onClick={()=>  setShowGroupModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span className="closing_cross" aria-hidden="true">&times;</span>
            </button>
          
            <div className="text-center p-2">
              <p className='text-danger mb-5'>Please buy some additional credits to book meetings.
                </p>
              <a href={meetings_data.credits_payment_link} target="_blank" rel="noreferrer" >
                <button className="btn_primary btn-lg float-start">Buy Credits</button>
              </a>
            </div>
          </div>

          :
          <>
          <Modal.Header className='zindex-modal-header pb-3'>
            <button onClick={()=>  setShowGroupModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span className="closing_cross" aria-hidden="true">&times;</span>
            </button>

            {show_buy_credit && (
              <div className='row mb-4'>
                <h5 className="col-sm-8 colored mt-3 mb-3"> You have { activeCredits} credits  left</h5>
                <div className="col-sm-4">
                  <a className="btn_primary btn-sm" href={meetings_data.credits_payment_link} target="_blank" rel="noreferrer" >
                    Buy Credits
                  </a>
                </div>
              </div>
            )}
          </Modal.Header>
          
          <Modal.Content scrolling>
            <Modal.Description >
            <div>
              {showGroups?
                <> 
                <h4 className='pb-4'>I would like to schedule...</h4>
                  {
                    groupsData.map((meeting, index) => (
                      <div className="card mb-4" 
                      style={{background: (meeting.booked || meeting.subscribed)? "#f3fbff" : "" }} 
                      key={index}> 
                        <div className="card-body">
                          <div className="row">
                            <div className='col-12 col-sm-9'>
                              <h5 className="title mb-3"> {meeting.title}</h5> 
                              <h6 className="card-subtitle mb-4 text-muted">
                                {meeting.meet_length_char} on {meeting.meet_day} at&nbsp; 
                                {new Date(meeting.utc_start_time  ).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                                &nbsp;{ timezone }
                              </h6>
                            </div>
                            <div className='col-12 col-sm-3'>
                              <button 
                                className={`btn-secondary btn bg-dark ${meeting.booked && " disabled" } `} 
                                id={meeting.id} 
                                onClick={BookMeeting}
                              >
                                {meeting.booked ? "Booked" : " Book Now" }
                              </button>
                            </div>
                          </div>
                          <div className="card-text">
                          {meeting.description ?
                            <p>
                              {meeting.description}
                            </p>
                            :
                            <p className='text-muted'><br/></p>
                          
                          }
                          </div>
                          { meeting.subscribed && (
                            <div className='mt-2'>
                              <a 
                                className="text-bold text-info"
                                onClick={(e) => {
                                  unSubscribe(meeting.id)
                                  e.stopPropagation
                                }}
                              >
                                Unsubscribe from future '{meeting.title}' meetings
                              </a>
                            </div>

                          )}
                        </div>
                      </div>
                    ))
                  }
                  </>
                :
                <formContext.Provider value={[activeCredits, setActiveCredits, setShowGroups, groupsData, setGroupsData]}>
                <GroupForm 
                  group_id = {meetingId}
                  zoom_email= {current_user.email}
                  first_name = {current_user.first_name}
                  last_name = {current_user.last_name}
                  authToken={authToken}
                  />
                </formContext.Provider>

              }

            </div>
            </Modal.Description>
          </Modal.Content>
          </>
          }
              
        </Modal>
      </>
    

      <Modal
        centered={true}
        open={showSession}
        tabIndex="-1"
        role="dialog"
        size="large"
        dimmer={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
        className='session-book'
      >
        <Modal.Content>
          <Modal.Description >
            <div className='p-3 col-12'>
              <button onClick={()=>setShowSession(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span className="closing_cross" aria-hidden="true">&times;</span>
              </button>
              <h3>Schedule a session</h3>
            </div>
            <iframe
            src={`${booking_link}&${firstName}${lastName}${phone}${email}`}
            title="Schedule Appointment"
            width="100%"
            height="800"
            frameBorder="0"
          ></iframe>
          </Modal.Description>
        </Modal.Content>
            
      </Modal>
    </>
    
  )
}

export default GroupMeetingModal

export {formContext}
